<template>
  <div class="page question-page">
    <div class="share" @click="share = true">
      <img src="../../assets/images/share.png" />
      <p>我要分享</p>
    </div>

    <div class="guide" @click="share = false" v-if="share">
      <img src="../../assets/images/share-tips.png" />
    </div>

    <div class="result" :class="`r${index + 1}`" id="result">
      <div class="pop">
        <div class="circle"></div>
        <div class="content">
          <h4>
            <img :src="user.avatar" />
            {{ user.nickname }}
            您独有的心音密码是
          </h4>
          <p class="title">{{ result.title }}</p>
          <div class="line"></div>
          <p v-for="row in result.content" :key="row">{{ row }}</p>
        </div>
      </div>
      <div class="message">
        <div class="content">
          <h2><i>#</i> 解码我的【爱之超能力】<i>#</i></h2>
          <p>2月1日-2月28日</p>
          <p>“两个人的心跳” 声音艺术展于PAFC MALL展出</p>
          <p>由【音糖生命博物馆】打造不同声音密码</p>
          <p>带您身临其境，沉浸曼妙声音故事</p>
          <h4>展览位置</h4>
          <h4>平安金融中心商场L1、L2、L3</h4>

          <div class="qrcode">
            <img
              :src="`https://pafc.navimax.cn/api.php/qrcode?open_id=${user.open_id}`"
            />
            <p>长按分享给朋友</p>
            <p>解码爱的超能力 抽好礼</p>
          </div>
        </div>
      </div>
    </div>
    <p class="save">长按保存图片</p>
    <p class="tips">立即分享好友 抽取大奖</p>
    <div class="action">
      <button class="btn" @click="save">幸运抽奖</button>
    </div>
  </div>

  <div class="prize" v-if="status">
    <div class="container animate__animated animate__bounceIn">
      <div class="title">{{ prize.title }}</div>
      <div class="info">
        <p>{{ prize.msg }}</p>
        <p class="tips" v-html="prize.tips"></p>
        <button @click="confirm">确认</button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import html2canvas from "html2canvas";

export default {
  name: "Result",

  data() {
    return {
      result: {},

      prize: {
        title: "恭喜您抽中",
        msg: "Teastone联名袋",
        tips: "温馨提示：请凭中奖信息，到客服台兑换。<br />兑换截止日志：2月28日前",
      },

      status: false,

      share: false,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.detail.user,
      record: (state) => state.detail.record,
    }),

    index() {
      return (
        (this.record[0] + this.record[1] + this.record[2] + this.record[3]) % 6
      );
    },
  },

  mounted() {
    this.init();

    this.$store.dispatch("share/config");

    setTimeout(() => {
      const target = document.querySelector("#result");
      const container = target.parentNode;

      html2canvas(target, { backgroundColor: null, useCORS: true }).then(
        (canvas) => {
          let img = document.createElement("img");
          img.classList.add("result");
          img.src = canvas.toDataURL();
          container.replaceChild(img, target);
        }
      );
    }, 1000);
  },

  methods: {
    confirm() {
      this.status = false;
    },

    init() {
      const result = [
        {
          title: "阳光甜爱力",
          content: [
            "您的内心充满阳光，",
            "爱憎分明，",
            "和您在一起会感到快乐。",
            "对于爱情有热烈的憧憬，",
            "也有着坚定的执着。",
          ],
        },
        {
          title: "浪漫氛围力",
          content: [
            "您的内心细腻温柔，",
            "情绪丰富，充满浪漫想象。",
            "有时会想要给对方惊喜，",
            "同时要求对方也能给予回应，",
            "适当放低标准会让彼此的",
            "相处更舒适。",
          ],
        },
        {
          title: "宁静内敛力",
          content: [
            "您的内心藏着一片海洋，",
            "宁静，大气，不爱计较。",
            "对于爱情，",
            "您有时会没那么敏锐，",
            "有小情绪也会默默消化，",
            "以免影响彼此关系。",
          ],
        },
        {
          title: "活力萌趣力",
          content: [
            "您的内心仿佛时时刻刻",
            "爱憎分明，",
            "都充满新鲜想法，",
            "认识您的人都觉得您是有趣的人。",
            "对于爱情也常有惊人之举，",
            "希望经常能拥有惊喜。",
          ],
        },
        {
          title: "无染净化力",
          content: [
            "您的内心纯净，",
            "哪怕经历过很多事情，",
            "仍然善良温柔。",
            "对于爱情，",
            "您最不能接受的行为就是欺骗。",
          ],
        },
        {
          title: "神秘魅惑力",
          content: [
            "您的内心充满神秘气息，",
            "有时候自己也难以捕捉",
            "转瞬即逝的灵感。",
            "您希望对方能懂您的心，",
            "而不用事事都要说透。",
          ],
        },
      ][this.index];

      this.result = result;
    },

    async save() {
      const { data } = await this.$fetch("record", {
        method: "post",
        body: {
          record: [
            this.record[0],
            this.record[1],
            this.record[2],
            this.record[3],
          ],
        },
      });

      if (data.code != 0) {
        this.$layer.msg(data.message);
        return;
      }

      const prize = data.data;

      if (prize.prize) {
        this.prize = {
          title: "恭喜您抽中",
          msg: [
            "Teastone联名袋",
            "敏华菠萝包",
            "速干毛巾+拉力带",
            "心音采录VIP体验(赠USB)",
            "雪瑞儿 梳子",
            "U型枕",
            "盖毯",
            "正当红联名口红",
            "gaga鲜花兑换券",
          ][prize.prize - 1],
          tips: "温馨提示：请凭中奖信息，到客服台兑换。<br />兑换截止日志：2月28日前",
        };
      } else {
        this.prize = {
          title: "太可惜了",
          msg: "很遗憾与大奖擦肩而过",
          tips: "请持续关注PAFC MALL下次还有更多福利",
        };
      }

      this.status = true;
    },
  },
};
</script>
<style>
img.result {
  width: 100%;
  display: block;
  position: relative;
  z-index: 99;
}
</style>
<style scoped lang="less">
.prize {
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.2);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200;

  .container {
    margin: 0 auto;
    padding: 30px 30px 80px 30px;
    width: 300px;
    height: 240px;
    background: url("../../assets/images/prize-bg.png") no-repeat center / cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      width: 150px;
      height: 64px;
      font-size: 18px;
      font-weight: 500;
      color: #d12038;
      text-align: center;
      line-height: 64px;
      background: url("../../assets/images/prize-title.png") no-repeat center /
        cover;
      position: absolute;
      top: -32px;
      left: 75px;
    }

    p {
      margin: 10px;
      font-size: 18px;
      color: #fff;
      text-align: center;
    }

    .tips {
      margin: 0;
      padding: 0 30px;
      width: 100%;
      font-size: 12px;
      color: #f3bdc4;
      position: absolute;
      left: 0;
      bottom: 50px;
    }

    button {
      width: 100px;
      height: 30px;
      color: #fff;
      border: 1px solid #000;
      border-radius: 4px;
      background: #dea58a;
      position: absolute;
      bottom: 12px;
      left: 100px;
    }
  }
}

.share {
  margin: 0 0 10px 0;
  text-align: center;
  float: right;

  img {
    width: 40px;
    height: 40px;
  }

  p {
    margin: 0;
  }
}

.guide {
  width: 100%;
  height: 100%;
  background: rgba(#000, 0.8);
  position: fixed;
  z-index: 300;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.result {
  padding: 5px 10px 10px 10px;
  background: #d84762;
  position: relative;
  clear: both;
  z-index: 99;

  .pop {
    position: relative;
    color: #000;

    .content {
      padding: 30px 20px 70px 20px;
      background: url("../../assets/images/r1.png") bottom right / contain
        no-repeat;
      position: relative;
      z-index: 101;
    }

    h4 {
      margin: 0;
      font-weight: 500;
    }

    img {
      margin-right: 5px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      vertical-align: middle;
    }

    p {
      margin: 0;
      line-height: 30px;
    }

    .title {
      margin: 20px 0 10px 0;
      font-size: 30px;
      font-weight: normal;
      letter-spacing: 3px;
      color: #d84661;
    }

    .line {
      margin: 30px 0 15px 0;
      width: 26px;
      height: 0;
      border-bottom: 1px solid #000;
    }

    .circle {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #c38bba;
      opacity: 0.8;
      position: absolute;
      top: 30px;
      right: 20px;
      z-index: 100;
    }

    &::before {
      width: 100%;
      height: 100%;
      border-top-right-radius: 160px;
      content: "";
      background: #eca3b1;
      position: absolute;
      left: 0;
      top: 5px;
      z-index: 100;
    }
  }

  .message {
    padding: 20px 0;
    text-align: center;
    color: #000;
    position: relative;

    .content {
      margin: 0 15px;
      background: #ffffff;
      position: relative;
      z-index: 101;
    }

    h2 {
      margin-top: 0;
      font-size: 16px;
      font-weight: 500;
      color: #d84762;
    }

    p {
      margin: 0;
      line-height: 25px;
    }

    h4 {
      margin: 0;
      font-weight: 500;
      line-height: 25px;
    }

    .qrcode {
      margin-top: 10px;

      img {
        margin-bottom: 5px;
        width: 100px;
      }

      p {
        margin: 0;
        line-height: 16px;
        font-size: 12px;
      }
    }

    &::before,
    &::after {
      width: 100%;
      height: 50%;
      content: "";
      background: #ffffff;
      position: absolute;
      left: 0;
      z-index: 100;
    }

    &::before {
      border-radius: 0 0 15px 15px;
      top: 0;
    }

    &::after {
      border-radius: 15px 15px 0 0;
      bottom: 0;
    }
  }

  &.r2 {
    background-color: #ecade5;

    .pop {
      .circle {
        background-color: #f47458;
      }

      .title {
        color: #d84762;
      }

      .content {
        background-image: url("../../assets/images/r2.png");
      }

      &::before {
        background: #f6d6f2;
      }
    }
  }

  &.r3 {
    background-color: #97b8e8;

    .pop {
      .circle {
        background-color: #96d9c9;
      }

      .title {
        color: #163560;
      }

      .content {
        background-image: url("../../assets/images/r3.png");
      }

      &::before {
        background: #cbdcf4;
      }
    }
  }

  &.r4 {
    background-color: #ffafc6;

    .pop {
      .circle {
        background-color: #ff5b20;
      }

      .title {
        color: #ff5b20;
      }

      .content {
        background-image: url("../../assets/images/r4.png");
      }

      &::before {
        background: #ffd7e3;
      }
    }
  }

  &.r5 {
    background-color: #74c6be;

    .pop {
      .circle {
        background-color: #a3e5ff;
      }

      .title {
        color: #74c6be;
      }

      .content {
        background-image: url("../../assets/images/r5.png");
      }

      &::before {
        background: #bae3df;
      }
    }
  }

  &.r6 {
    background-color: #ccceff;

    .pop {
      .circle {
        background-color: #ff8490;
      }

      .title {
        color: #ff8490;
      }

      .content {
        background-image: url("../../assets/images/r6.png");
      }

      &::before {
        background: #d9dbff;
      }
    }
  }
}

.save {
  margin: 0;
  text-align: right;
  font-size: 13px;
  color: #dea58a;
}

.tips {
  font-size: 16px;
  margin: 10px 0 0 0;
  text-align: center;
}

.action {
  margin-top: 0px;
}
</style>
